/* src/css/styles.css */

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) { 
	#main {
		margin-top: 80px;
	}
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) { 
	#header #logo {
		max-width: 140px;
	}
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {

}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
	#main {
		padding-top: 10px;
	}

	#header #logo {
		max-width: 160px;
	}
}

body {
	font-family: 'Source Sans Pro';
	font-weight: 400;
	overflow-y: scroll;
}

#global-loader {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: rgba(23, 23, 23, 0.95);
	color: #fff;
	z-index: 99;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

#overlay {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: rgba(23, 23, 23, 0.9);
	z-index: 99;
}

#overlay-close {
	position: absolute;
	top: 15px;
	right: 10px;
	background-color: #232323;
	border: 1px solid #fff;
	border-radius: 2px;
	color: #fff;	
}

#header-mobile {
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	height: 60px;
	background-color: #fff;
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.2);
	z-index: 99;
}

#header-mobile #logo {
	position: absolute;
	top: 10px;
	left: 10px;
	width: 40px;
	height: auto;
}

#header-mobile h1 {
	position: absolute;
	top: 10px;
	left: 60px;
	font-size: 1rem;
	line-height: 20px;
	text-transform: uppercase;
	font-weight: 900;
	color: #232323;
	margin: 0;
}

#show-mobile-menu {
	position: absolute;
	top: 15px;
	right: 10px;
	background-color: #fff;
	border: 1px solid #232323;
	border-radius: 2px;
	color: #232323;
}

#menu-mobile {
    padding-top: 62px;
}

#menu-mobile ul {
    list-style-type: none;
}

#menu-mobile a {
    display: block;
    border: 1px solid #fff;
    border-radius: 4px;
    padding: 8px 12px;
    margin: 0 24px 24px 24px;
    color: #fff;
    text-decoration: none;
}

#left-column {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	background-color: #fff;
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.2);
	z-index: 2;
}

#header {
	padding-top: 15px;
	text-align: center;
}

#header #logo {
	width: auto;
	height: auto;
}

#header h1 {
	font-size: 2vw;
	font-weight: 900;
	text-transform: uppercase;
	margin-top: 10px;
}

#main {
	position: absolute;
	top: 0;
	right: 0;
	padding-bottom: 50px;
	background-color: #fff;
	z-index: 1;
	margin: 0;
}

hr {
	width: 100%;
	height: 1px;
	border: 0;
	background: -moz-linear-gradient(left,  rgba(255,255,255,0) 0%, rgba(247,247,247,0.75) 25%, rgba(247,247,247,1) 50%, rgba(247,247,247,0.75) 75%, rgba(255,255,255,0) 100%);
	background: -moz-linear-gradient(left,  rgba(255,255,255,0) 0%, rgba(185,190,150,0.75) 25%, rgba(185,190,150,1) 50%, rgba(185,190,150,0.75) 75%, rgba(255,255,255,0) 100%);
	background: -webkit-linear-gradient(left,  rgba(255,255,255,0) 0%,rgba(185,190,150,0.75) 25%,rgba(185,190,150,1) 50%,rgba(185,190,150,0.75) 75%,rgba(255,255,255,0) 100%);
	background: linear-gradient(to right,  rgba(255,255,255,0) 0%,rgba(185,190,150,0.75) 25%,rgba(185,190,150,1) 50%,rgba(185,190,150,0.75) 75%,rgba(255,255,255,0) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#00ffffff',GradientType=1 );
}

#nav ul {
	list-style-type: none;
	padding: 0;
}

#nav a {
    display: inline-block;
    position: relative;
    font-family: 'Source Sans Pro', sans-serif;
    letter-spacing: 0.5px;
    font-size: 1.5vw;
    text-transform: uppercase;
    color: #232323;
    padding-left: 1rem;
    transition: all ease 0.2s;
}

#nav a:hover, nav #a.active {
    color: #232323;
    padding-left: 2rem;
    text-decoration: none;
}

#nav a:hover::before, #nav a.active::before {
    content: '';
    position: absolute;
    left: 0;
    top: 2px;
    bottom: 2px;
    width: 8px;
    background-color: #b9be96;
}

/* social links */
#social-links {
	position: absolute;
	right: 0;
	bottom: 15px;
	left: 0;
	display: flex;
	justify-content: center;
}

.social-link {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: 10px;
	color: #232323;
	transform: scale(1);
	transition: all cubic-bezier(0.755, 0.05, 0.855, 0.06) 0.2s;
	background-color: #fff;
	width: 32px;
	height: 32px;
	border: 1px solid #232323;
	border-radius: 50%;
	-webkit-box-shadow: 0px 0px 25px 0px rgba(0,0,0,0.2); 
	box-shadow: 0px 0px 25px 0px rgba(0,0,0,0.2);
}

#social-links a:last-child {
	margin-right: 0;
}

.social-link:hover {
	transform: scale(1.2);
	color: #232323;
}

/* modules common styles */
.module {
	margin: 15px 0;
}

.module-header {
	margin-bottom: 15px;
}

.module-header h2 {
	font-size: 1.8vw;
	font-weight: 900;
	text-transform: uppercase;
	color: #232323;
}

.module-header h2 svg {
	font-size: 60%;
	margin: 0 10px 3px 0;
	font-weight: 400;
	color: #b9be96;
	opacity: 0.75;
	transform: scale(1);
	transition: all ease 0.2s;
}

.module:hover h2 svg {
	opacity: 1;
	transform: scale(1.4);
}

.module-content {
	position: relative;
}

#presentation::before, #carte::before, .single .presentation::before {
	content: '';
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	width: 1px;
	background: -moz-linear-gradient(top,  rgba(255,255,255,0) 0%, rgba(247,247,247,0.75) 25%, rgba(247,247,247,1) 50%, rgba(247,247,247,0.75) 75%, rgba(255,255,255,0) 100%);
	background: -moz-linear-gradient(top,  rgba(255,255,255,0) 0%, rgba(185,190,150,0.75) 25%, rgba(185,190,150,1) 50%, rgba(185,190,150,0.75) 75%, rgba(255,255,255,0) 100%);
	background: -webkit-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(185,190,150,0.75) 25%,rgba(185,190,150,1) 50%,rgba(185,190,150,0.75) 75%,rgba(255,255,255,0) 100%);
	background: linear-gradient(to bottom,  rgba(255,255,255,0) 0%,rgba(185,190,150,0.75) 25%,rgba(185,190,150,1) 50%,rgba(185,190,150,0.75) 75%,rgba(255,255,255,0) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#00ffffff',GradientType=1 );
}

.loader {
	width: 100%;
	height: 100%;
	min-height: 240px;
	background-color: #f7f7f7;
	display: flex;
	align-items: center;
	justify-content: center;
}

/* actualites */
.actualite-wrapper {
    position: relative;
    width: 100%;
    height: 240px;
    overflow: hidden;
}

.actualite {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 40px;
    border: 1px solid #ccc;
}

.actualite-photo, .actualite-video {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    max-width: 300px;
}

.actualite-content-alone {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #f7f7f7;
}

.actualite-content-with-before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: calc(100% - 300px);
    background-color: #f7f7f7;
}

.actualite-content {
	padding: 15px;
}

.actualite-content h3 {
	font-size: 1.4vw;
}

.carousel {
    margin: 0 40px;
}

.carousel-control-prev, .carousel-control-next {
    background: none;
    bottom: 40px;
    opacity: 1;
	cursor: pointer;
}

.carousel-control-prev {
	width: auto;
    left: -60px;
}

.carousel-control-next {
	width: auto;
    right: -60px;
}

.leftControl > i {
    left: 0;
    margin-left: 15px;
}

.rightControl i {
    right: 0;
    margin-right: 15px;
}

.leftControl > i, .leftControl > span, .rightControl > i, .rightControl > span {
    color: #232323;
}

.carousel-control-prev:hover, .carousel-control-next:hover {
    background: none;
	color: #b9be96 !important;
	opacity: 1 !important;
}

.carousel-indicators {
    margin: 0;
    padding: 0;
}

.carousel-indicators li {
    height: 20px;
    background-color: #232323;
    opacity: 1;
    transform-origin: bottom;
    transform: scaleY(1);
    transition: all ease 0.2s;
	margin: 0 12px 0 0 !important;
	border: 0;
}

.carousel-indicators li.active {
    transform: scaleY(1.5);
    background-color: #b9be96;
}

.carousel-indicators li:hover {
    background-color: #b9be96;
    opacity: 1;
    transform: scaleY(1.5);
}

.carousel-indicators li.active:hover {
    cursor: default;
}

.presentation-content {	
	height: 240px;
	padding-right: 20px;
}

.presentation-content p {
	text-align: justify;
}

.ps__rail-y {
	opacity: 0.6;
}

/* carte */
.leaflet-container {
  	width: 100%;
  	height: 360px;
}

/* masonry layout for posts list */
.ms-grid {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	width: auto;
	margin-left: -15px;
}

.ms-grid-column {
	padding-left: 15px;
	background-clip: padding-box;
}

.ms-grid-column > div {
	background: grey;
	margin-bottom: 15px;
}

/* masonry posts list elements */
a.oeuvre, a.oeuvre-diapo {
	position: relative;
	display: block;
	height: 100%;
	text-decoration: none;
}

a.oeuvre > img, a.oeuvre-diapo > img {
	min-width: 100%;
}

a.oeuvre .post-title, a.oeuvre-diapo .post-title {
	content: '';
	position: absolute;
	top: 0;
  	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	background-color: rgba(255, 255, 255, 0.4);
	transform: scale(0);
	transition: all cubic-bezier(0.755, 0.05, 0.855, 0.06) 0.2s;
}

a.oeuvre:hover .post-title, a.oeuvre-diapo:hover .post-title {
	transform: scale(1);
}

a.oeuvre .post-title h3, a.oeuvre-diapo .post-title h3 {
	position: relative;
	font-family: 'Source Sans Pro', sans-serif;
	line-height: 1;
	font-size: 1.8vw;
	text-transform: uppercase;
	padding: 0;
	color: #232323;
	text-align: center;
}

a.oeuvre h3::before, a.oeuvre-diapo h3::before {
	content: '';
	position: absolute;
	top: -10px;
	right: 0;
	left: 0;
	width: 100%;
	height: 1px;
	border: 0;
	background: -moz-linear-gradient(left,  rgba(35,35,35,0) 0%, rgba(35,35,35,0.6) 10%, rgba(35,35,35,1) 50%, rgba(35,35,35,0.61) 90%, rgba(35,35,35,0.6) 91%, rgba(35,35,35,0) 100%);
	background: -webkit-linear-gradient(left,  rgba(35,35,35,0) 0%,rgba(35,35,35,0.6) 10%,rgba(35,35,35,1) 50%,rgba(35,35,35,0.61) 90%,rgba(35,35,35,0.6) 91%,rgba(35,35,35,0) 100%);
	background: linear-gradient(to right,  rgba(35,35,35,0) 0%,rgba(35,35,35,0.6) 10%,rgba(35,35,35,1) 50%,rgba(35,35,35,0.61) 90%,rgba(35,35,35,0.6) 91%,rgba(35,35,35,0) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00232323', endColorstr='#00232323',GradientType=1 );
	transform: scaleX(0);
	transition: all cubic-bezier(0.755, 0.05, 0.855, 0.06) 0.5s;
}

a.oeuvre .post-title h3::after, a.oeuvre-diapo .post-title h3::after {
	content: '';
	position: absolute;
	right: 0;
	left: 0;
	bottom: -10px;
	width: 100%;
	height: 1px;
	border: 0;
	background: -moz-linear-gradient(left,  rgba(35,35,35,0) 0%, rgba(35,35,35,0.6) 10%, rgba(35,35,35,1) 50%, rgba(35,35,35,0.61) 90%, rgba(35,35,35,0.6) 91%, rgba(35,35,35,0) 100%);
	background: -webkit-linear-gradient(left,  rgba(35,35,35,0) 0%,rgba(35,35,35,0.6) 10%,rgba(35,35,35,1) 50%,rgba(35,35,35,0.61) 90%,rgba(35,35,35,0.6) 91%,rgba(35,35,35,0) 100%);
	background: linear-gradient(to right,  rgba(35,35,35,0) 0%,rgba(35,35,35,0.6) 10%,rgba(35,35,35,1) 50%,rgba(35,35,35,0.61) 90%,rgba(35,35,35,0.6) 91%,rgba(35,35,35,0) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00232323', endColorstr='#00232323',GradientType=1 );
	transform: scaleX(0);
	transition: all cubic-bezier(0.755, 0.05, 0.855, 0.06) 0.5s;
}

a.oeuvre:hover .post-title h3::before, a.oeuvre:hover .post-title h3::after, a.oeuvre-diapo:hover .post-title h3::before, a.oeuvre-diapo:hover .post-title h3::after {
	transform: scaleX(1);
}

/* single */
#section-header {
	margin: 15px 0;
}

#section-header h2 {
	font-size: 2vw;
	font-weight: 600;
	text-transform: uppercase;
	margin: 0;
}

/* lightboxes */
#SRLLightbox {
	z-index: 100;
}

/* share */
#share {
	position: fixed;
	right: 12px;
	top: 12px;	
	z-index: 2;
	width: 32px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: stretch;
}

#share .social-link {
	margin: 0 0 25px 0;
}

#share-links div:last-child .social-link {
	margin: 0;
}

#show-share button:hover {
	transform: scale(1.2);
}

#share #share-links {
	transform: scaleY(0);
	transform-origin: top;
	transition: all cubic-bezier(0.755, 0.05, 0.855, 0.06) 0.2s;
}

#share:hover #share-links {
	transform: scaleY(1);
}

/* footer */
#footer {
	position: fixed;
	right: 0;
	bottom: 0;
	z-index: 2;
}

.footer-content {
	background-color: #fff;
    -webkit-box-shadow: 0px 0px 25px 0px rgba(0,0,0,0.2); 
	box-shadow: 0px 0px 25px 0px rgba(0,0,0,0.2);
	padding: 8px;
	text-align: center;
}

.footer-content h3 {
	margin: 0;
	font-size: 1vw;
	font-weight: 900;
	text-transform: uppercase;
}

.footer-content p {
	font-size: 0.8vw;
	margin: 0;
}
